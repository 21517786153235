import React, { useEffect, useState } from 'react';
import VideoGallery from './VideoGallery';

const API = process.env.REACT_APP_API || 'localhost:3000'

const App: React.FC = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchVideos = async () => {
      try {
        const response = await fetch(API);
        const data = await response.json();
        
        data.sort((a: any, b: any) => Date.parse(b.date) - Date.parse(a.date))
        // Assuming the API response is an array of video objects
        setVideos(data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    // Call the fetchVideos function when the component mounts
    fetchVideos();
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <div className='App'>
      <h1 className='title'>Μεμονωμένα Περιστατικά</h1>
      <h2>Αναφορά μεμονωμένου περιστατικoύ <a href="https://docs.google.com/forms/d/e/1FAIpQLSewfiCTREiVH7TymjnjRc7IliTetqo2V066PTzgPGMYyvx17A/viewform">εδώ</a></h2>
      {/* Render the VideoGallery component once the videos are fetched */}
      <VideoGallery videos={videos} />
    </div>
  );
};

export default App