import React from 'react';
import ReactPlayer from 'react-player/lazy';
import './App.css';

interface Video {
  url: string;
  thumbnail: string;
  description: string;
  location: string;
  date: string;
}

interface VideoGalleryProps {
  videos: Video[];
}

const VideoGallery: React.FC<VideoGalleryProps> = ({ videos }) => {
  return (
    <div className="video-gallery">
      {videos.map((video, index) => (
        <div key={index} className="video-container">
          <ReactPlayer 
            light={video.thumbnail || ''}
            width="100%"
            className="video-player"
            url={video.url}
            controls />
          <div className="video-description">
            <p>{video.date} - {video.location || 'Άγνωστο'}</p>
            <p>{video.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoGallery;
